import React from 'react'
import Footer from './Footer'
import styles from './container.css'

export default ({ children }) => (
  <div className="app-wrapper">
    {children}
    <Footer />
  </div>
)
