import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components';
import { Location } from '@reach/router';
import ReactGA from 'react-ga'
import { STYLES } from '../styles/constants'
import logo from '../images/logo.png'
import { AnchorButtonStyle, buttonThemes } from '../components/Button'
import slash from '../images/slash.svg'

const AppHeader = styled.header`
  display: flex;
  justify-content: space-between;
  max-width: ${STYLES.MAX_WIDTH_PADDING};
  padding: 0 16px;
  margin: 0 auto;
  height: 72px;
  align-items: center;

  .logo {
    img {
      height: 36px;
      width: auto;

      &.fixed {
        z-index: 5;
        position: fixed;
        left: 16px;
        top: 10px;
      }
    }
  }

  .nav-toggle {
    display: none;
  }

  @media (max-width: ${STYLES.BP_MEDIUM_MAX}) {
    height: 56px;

    .nav-toggle {
      display: block;
      padding: 4px 2px;
      cursor: pointer;

      &.open {
        position: fixed;
        top: 18px;
        right: 16px;
        z-index: 5;
      }
    }

    .nav-icon {
			width: 18px;
			height: 12px;
			position: relative;
			transform: rotate(0deg);
			transition: .5s ease-in-out;

			span {
				display: block;
				position: absolute;
				height: 2px;
				width: 100%;
				background: #FFFFFF;
				opacity: 1;
				left: 0;
				transform: rotate(0deg);
				transition: .25s ease-in-out;

				&:nth-child(1) {
				  top: 0px;
				}

				&:nth-child(2),&:nth-child(3) {
				  top: 5px;
				}

				&:nth-child(4) {
				  top: 10px;
				}
			}
      
      /* OPEN MENU */
      &.open {
        span {
					background: #ffffff;
				}

				span:nth-child(1) {
				  top: 5px;
				  width: 0%;
				  left: 50%;
				}

				span:nth-child(2) {
				  -webkit-transform: rotate(45deg);
				  -moz-transform: rotate(45deg);
				  -o-transform: rotate(45deg);
				  transform: rotate(45deg);
				}

				span:nth-child(3) {
				  -webkit-transform: rotate(-45deg);
				  -moz-transform: rotate(-45deg);
				  -o-transform: rotate(-45deg);
				  transform: rotate(-45deg);
				}

				span:nth-child(4) {
				  top: 5px;
				  width: 0%;
				  left: 50%;
				}
      }
    }
  }
`;

const Nav = styled.nav`
  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 100px;
    font-size: 1.25em;
  }

  li {
    display: inline-flex;
    align-items: center;
    margin-right: 36px;
    font-size: 12px;
    opacity:1;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 20px;
    text-transform: uppercase;
    transition: all 0.25s ease-out;
  }

  li:last-of-type {
    margin-right: 0;
  }

  li a.nav-link {
    opacity:0.5;
    transition: all 0.25s ease-out;

    :hover {
      opacity:1;
    }

    &.active {
      font-weight: ${STYLES.FW_BOLD};
      opacity:1;
      cursor:default;
      click-events:none;
    }
  }

  @media (max-width: ${STYLES.BP_MEDIUM_MAX}) {
      ul.nav-links {
        display: none;

        &.open {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          padding-top: 84px;
          padding-bottom: 20px;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #221F1F;
          min-height: 100%;
          z-index: 4;

          li {
            margin: 13px 0;

            a.nav-link {
              padding: 14px 0;
              font-family: "Montserrat";
              font-weight: ${STYLES.FW_SEMIBOLD};
              font-size: 14px;
              color: #FFFFFF;
              letter-spacing: 0.5px;
              text-align: center;
              display: block;
              position: relative;

              span {
                position: relative;
                z-index: 7;
              }

              &.active:after, &.active:before {
                content: '';
                display: block;
                position: absolute;
              }

              &.active:before {
                width: 11px;
                height: 48px;
                top: 0;
                left: calc(50% - 5.5px);
                z-index: 5;
                background-image: url(${slash});
                background-size: 100% 100%;
              }

              &.active:after {
                width: 100%;
                height: 24px;
                background-color: #221F1F;
                top: 12px;
                left: 0;
                z-index: 6;
              }
            }

            .btn-contact {
              margin-top: 14px;
            }
          }
        }
      }
    }
`;

export default class Navigation extends React.Component { 

  constructor(props) {
    super(props)

    this.state = {
      mobileMenuOpen: false
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.closeMenu);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.closeMenu);
  }

  toggleMenu = () => {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen })
  }

  closeMenu = () => {
    this.setState({mobileMenuOpen: false})
  }

  trackClick = type => {
    let event = {
      category: 'Navigation',
      action: '',
      label: ''
    }

    if (type === 'contact') {
      event.action = 'Click contact us (main navigation)'
      event.label = 'Contact Us'
    }
    else if (type === 'logo') {
      event.action = 'Go home'
      event.label = 'Home - Logo'
    }
    else if (type === 'home') {
      event.action = 'Go home'
      event.label = 'Home - Navigation Link'
    }

    ReactGA.event(event)
    this.closeMenu()
  }

  render() {
    return (
      <AppHeader>
        <div className="logo"><Link to="/" onClick={() => this.trackClick('logo')}><img src={logo} alt="Partners + Napier Logo" className={(this.state.mobileMenuOpen ? 'fixed' : '')} /></Link></div>
        <Location>
          {({ location }) => (
            <Nav role="Navigation">
              <div className={"nav-toggle" + (this.state.mobileMenuOpen ? ' open' : '')} onClick={this.toggleMenu}>
                <div className={'nav-icon' + (this.state.mobileMenuOpen ? ' open' : '')}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <ul className={"nav-links" + (this.state.mobileMenuOpen ? ' open' : '')}>
                <li><Link to="/" activeClassName="active" className="nav-link" onClick={() => this.trackClick('home')}><span>Home</span></Link></li>
                <li><Link to="/archive" activeClassName="active" className="nav-link" onClick={() => this.trackClick('archive')}><span>Archive</span></Link></li>
                <li><AnchorButtonStyle theme={buttonThemes.primary} className="btn-contact" href="mailto:krystal.garvin@partnersandnapier.com" onClick={() => this.trackClick('contact')}><span>Contact Us</span></AnchorButtonStyle></li>
              </ul>
            </Nav>
          )}
        </Location>
      </AppHeader>
    )
  }
}
