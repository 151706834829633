import styled from 'styled-components';
import { STYLES } from '../styles/constants'

export const Button = styled.button`
  display: flex;
  flex-shrink: 0;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: ${STYLES.FW_SEMIBOLD};
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 20px;
  border: 1px solid ${props => props.theme.borderColor};
  background-color: #221f1f;
  color: #fff;
  transition: background-color .3s, color .3s, border-color .3s;

  svg {
    margin-right: 15px;
    margin-left: -9px;
    path {
      fill: #fff;
      transition: fill .3s;
    }
  }

  :hover {
    background-color: #221f1f;
    color: ${STYLES.COLOR_PN_YELLOW};

    svg path {
      fill: ${STYLES.COLOR_PN_YELLOW};
    }
  }
`;

export const ButtonMobileCollapse = styled(Button)`
  @media (max-width: ${STYLES.BP_MEDIUM_MAX}) {
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 0;
    color: transparent;
    background-color: transparent;
    opacity:1;
    transition:all 0.25s ease-out;

    span {
      width: 0;
      height: 0;
    }

    svg {
      margin: 0;

      path {
        fill: ${STYLES.COLOR_PN_YELLOW};
      }
    }

    :hover {
      opacity:0.5;

      svg path {
      }
    }
  }
`;

export const AnchorButtonStyle = styled.a`
  display: inline-block;
  height: 40px;
  padding: 10px 24px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: ${STYLES.FW_SEMIBOLD};
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 20px;
  border: 1px solid ${props => props.theme.borderColor};
  background-color: ${props => props.theme.backgroundColor};
  color: #221f1f;
  transition: background-color .3s, color .3s, border-color .3s;

  svg {
    margin-right: 15px;
    margin-left: -9px;
    path {
      fill: #221f1f;
      transition: fill .3s;
    }
  }

  :hover {
    background-color: #221f1f;
    color: #fff;

    svg path {
      fill: #fff;
    }
  }
`;

const primary = {
  borderColor: STYLES.COLOR_PN_YELLOW,
  backgroundColor: STYLES.COLOR_PN_YELLOW,
  color: "#000000",
  hoverColor: "#FFFFFF",
  hoverBackgroundColor: "transparent",
  collapsedColor: STYLES.COLOR_PN_YELLOW,
  collapsedHoverColor: "#FFFFFF"
}

const secondary = {
  borderColor: STYLES.COLOR_PN_YELLOW,
  backgroundColor: "transparent",
  color: "#FFFFFF",
  hoverColor: "#000000",
  hoverBackgroundColor: STYLES.COLOR_PN_YELLOW,
  collapsedColor: "#FFFFFF",
  collapsedHoverColor: STYLES.COLOR_PN_YELLOW
}

Button.defaultProps = { theme: primary }
AnchorButtonStyle.defaultProps = { theme: primary }

export const buttonThemes = {
  primary, secondary
}