import React from 'react'
import styled from 'styled-components'
import Moment from 'react-moment'
import ReactGA from 'react-ga'
import { STYLES } from '../styles/constants'
import logo from '../images/logo-p.png'

const FooterContainer = styled.footer`
  padding: 35px 16px;
  max-width: ${STYLES.MAX_WIDTH_PADDING};
  margin: 0 auto;
  text-align: center;

  a {
    transition: color .3s;
  }

  img.logo {
    width: 40px;
    height: 50px;
    margin: 0 auto 22px auto;
  }

  ul {
    margin: 0 auto 20px auto;

    li {
      display: inline;
      font-family: "Montserrat";
      font-weight: ${STYLES.FW_MEDIUM};
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 20px;
      text-transform: uppercase;
      padding: 0 13px;
      position: relative;
      opacity:1;
      transition:all 0.25s ease-out;

      :after {
        content: '';
        display: block;
        width: 1px;
        height: 12px;
        position: absolute;
        right: 0;
        top: 1.5px;
      }

      :first-of-type {
        padding-left: 0;
      }

      :last-of-type {
        padding-right: 0;

        :after {
          display: none;
        }
      }

      :hover {
        opacity:0.5;
      }
    }
  }

  .contact {
    line-height: 22px;
    margin-bottom: 35px;
    opacity:1;
    transition: all 0.25s ease-out;

    strong {
      font-family: "Montserrat";
      font-weight: ${STYLES.FW_BOLD};
      text-transform: uppercase;
      font-size: 18px;

      a{
        transition: all 0.25s ease-out;
        opacity:1;
      }

      a:hover {
        opacity:0.5;
      }
    }

    .tel {
      font-size: 15px;
    }
  }

  .copyright {
    font-size: 10px;
    line-height: 30px;

    a:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${STYLES.BP_SMALL_MAX}) {
    padding-top: 40px;

    ul {
      margin-bottom: 28px;

      li {
        font-size: 9.5px;
        padding: 0 10px;

        :after {
          height: 9.5px;
        }
      }
    }

    .contact {
      margin-bottom: 22px;
    }
  }
`;

class Footer extends React.Component {
  
  trackClick = (label) => {
    let event = {
      category: 'Navigation',
      action: '',
      label: ''
    }

    if (label === 'contact') {
      event.action = 'Click contact us (footer)'
      event.label = 'Contact Us'
    }
    else if (label === 'privacy') {
      event.action = 'Click privacy policy'
      event.label = 'Privacy Policy'
    }
    else {
      event.action = 'Click social link'
      event.label = label
    }

    ReactGA.event(event)
  }

  render() {
    return (
      <FooterContainer>
        <img src={logo} alt="Partners + Napier Logo" className="logo" />
        <ul>
          <li><a href="https://www.linkedin.com/company/partners-and-napier" target="_blank" rel="noopener" onClick={() => this.trackClick('LinkedIn')}>LinkedIn</a></li>
          <li><a href="https://twitter.com/partnersnapier" target="_blank" rel="noopener" onClick={() => this.trackClick('Twitter')}>Twitter</a></li>
          <li><a href="https://facebook.com/partnersandnapier" target="_blank" rel="noopener" onClick={() => this.trackClick('Facebook')}>Facebook</a></li>
          <li><a href="https://www.instagram.com/partnersnapier/" target="_blank" rel="noopener" onClick={() => this.trackClick('Instagram')}>Instagram</a></li>
        </ul>
        <div className="contact">
          <strong><a href="mailto:krystal.garvin@partnersandnapier.com" className="contact-us" onClick={() => this.trackClick('contact')}>Contact Us</a></strong>
        </div>
        <div className="copyright">
          &copy; <Moment format="YYYY">{new Date()}</Moment> Partners + Napier<br/>
          <a className="privacy-link" href="https://www.partnersandnapier.com/privacy" target="_blank" rel="noopener" onClick={() => this.trackClick('privacy')}>Privacy Policy</a>
        </div>
      </FooterContainer>
    )
  }
}

export default Footer