export const STYLES = {
    MAX_WIDTH: '1136px',
    MAX_WIDTH_PADDING: '1168px',
    COLOR_PN_YELLOW: '#FBB415',
    COLOR_PN_YELLOW_DARK: "#E7A615",
    COLOR_GRAY_TEXT: "#8C8C8C",
    FW_LIGHT: "300",
    FW_REGULAR: "400",
    FW_MEDIUM: "500",
    FW_SEMIBOLD: "600",
    FW_BOLD: "700",
    FW_EXTRABOLD: "800",
    BP_MEDIUM_MIN: "768px",
    BP_MEDIUM_MAX: "767px",
    BP_SMALL_MIN: "490px",
    BP_SMALL_MAX: "489px",
    PADDING_MOBILE: "16px",
    HEIGHT_HEADER_DT: "72px",
    HEIGHT_HEADER_MB: "56px",
    HEIGHT_FOOTER: "324px"
};